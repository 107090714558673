var prodcat = prodcat || {};

prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};

var site = site || {};

site.template = site.template || {};

var generic = generic || {};

(function ($) {
  Drupal.behaviors.quickshopV1 = {
    /**
     * jQuery object that stores all the grids after they are set up in initializeGrid
     */
    $grids: $(),

    /**
     * Sets up the product grid for inline quickshop by inserting many js-quickshop-container div elements
     * based upon the data-grid-items-per-row attribute in the product grid (and screen size).
     * Each .js-quickshop-container div also gets specific productID classes so the proper product info
     * goes to the proper container on launch.
     * @param $grids - jQuery object of product grids on the page.
     * @param size - string such as "small" or "landscape" for determining the number of products in a row.
     */
    initializeGrid: function ($grids, size) {
      var self = this;

      if (typeof size === 'undefined') {
        size = Unison.fetch.now().name;
      }

      // Prep each grid (normally just one grid) by adding quickshop container div elements.
      $grids.has('.js-product-grid-item').each(function () {
        // Cache $(this) grid and remove any preexisting quickshop containers.
        var $thisGrid = $(this);

        self.reset($thisGrid);
        var i = 0;
        var classes = '';
        var rows = [];
        var $row = $();
        var $containerDiv = $('<div class="js-quickshop-container product-quickshop-wrapper" />');
        var $items = $('.js-product-grid-item:visible', $thisGrid);
        var totalItems = $items.length;
        var isCarousel = $(this).hasClass('product-grid--carousel');

        // Loop through each item in the product grid.
        $items.each(function (index) {
          var $thisItem = $(this);
          var productID = $thisItem.data('product-id');

          // If there is a product ID available, keep track of it to use in the current quickshop container.
          if (productID) {
            classes += ' js-quickshop-container--' + productID;
          }

          // For carousels, we just need all the classes
          if (isCarousel) {
            return;
            // Else if desktop, then products share a quickshop container according to the number per row in the grid.
          } else {
            var span = 1;
            var itemsPerRow = $thisGrid.data('grid-items-per-row')[size] || 3;

            i += span;
            $row = $row.add($thisItem);

            // The count has either reached the end of the row, or the end of the entire grid. Append the quickshop container!
            if (i === itemsPerRow || index + 1 === totalItems) {
              $thisItem.after($containerDiv.clone()).next().addClass(classes);
              rows.push($row);
              i = 0; // Reset the count.
              $row = $(); // Empty the jQuery object holding the current row of items.
              classes = ''; // Clear the class name list.
            }
          }
        });

        if (isCarousel) {
          // we only need one qs container for this
          $(this)
            .after($containerDiv.clone())
            .next()
            .addClass('product-quickshop-wrapper--carousel js-quickshop-container--carousel');
        }

        // Storage of completed grid and its rows.
        $thisGrid.data('product-grid-rows', rows);
        self.$grids.add($thisGrid);
      });
    },

    /**
     *  Clear the product grid of all quickshop containers.
     * @params $grid - jQuery object of product grid to reset
     */
    reset: function ($grid) {
      // Remove all quickshop div container elements.
      $('.js-quickshop-container', $grid).remove();
      // Remove active class from all product briefs.
      $('.js-product-brief', $grid).removeClass('active');
    },

    /**
     * Standard Drupal method
     */
    attach: function (context) {
      var $body = $('body', context);
      var $grid = $body.find('.js-product-grid');

      // If no grid, do nothing
      if (!$grid.length) {
        return;
      }
      // Get screen size.
      var size = Unison.fetch.now().name;

      this.initializeGrid($('.js-product-grid', context), size);
      $(document).trigger('product.quickshopInit', [
        {
          type: {
            overlay: true,
            toString: function () {
              return 'overlay';
            }
          },
          init: this.initializeGrid,
          reset: this.reset,
          $grids: this.$grids
        }
      ]);
    }
  };

  /**
   * 6 custom events below:
   * launchers
   * product.quickshop.launch.inline
   * product.quickshop.launch.inlineCarousel
   * product.quickshop.launch.overlay
   * closers
   * product.quickshop.close.inline
   * product.quickshop.close.inlineCarousel
   * product.quickshop.close.overlay
   */

  /**
   * QS Launcher
   * STANDARD MPP GRID - INLINE Quick Shop
   * product.quickshop.launch.inline
   * Uses the supplied .js-product to find the correct .js-quickshop-container to use.
   * Then loads it with the appropriate product data for the product_quickshop.mustache template.
   * Finally, shows the quickshop content inline.
   */
  $(document).on('product.quickshop.launch.inline', '.js-product', function () {
    // Store relevant product values.
    var $product = $(this);
    var productID = $product.data('product-id');
    var productData = prodcat.data.getProduct(productID);
    // Store other elements for inserting product info into quickshop container.
    var $grid = $product.closest('.js-product-grid');
    var $allProducts = $('.js-product-brief', $grid);
    var $containers = $('.js-quickshop-container', $grid);
    var $container = $containers.filter('.js-quickshop-container--' + productID); // Get specific container to use for this product.
    var $activeContainers = $containers.filter('.active');
    var $inlineOverlay = $('.js-qs-inline-overlay', '.js-mpp-container');
    // Initialize some other variables
    var alreadyActive = $product.hasClass('active');
    var template = 'product_quickshop';
    var animationSpeed = 400;
    var quickshopTop = 0;
    var prodHeight = $container.prev().height();
    // header and site banner heights to help account for offsets
    var navHeight = $('.site-header').height();
    var $siteBanner = $('body').find('.js-nav--banner');
    var siteBannerHeight = $siteBanner.height();

    // detect header and site banner heights
    if ($('html').hasClass('site-banner__is-open')) {
      navHeight = navHeight + siteBannerHeight;
    }

    // Close the quickshop inline if it is already active (and thefore already open).
    if (alreadyActive) {
      $product.trigger('product.quickshop.close.inline');

      return;
    }

    // Render the quickshop template.
    var rendered = site.template.get({
      name: template,
      data: productData
    });
    var $qs = $(rendered);

    if (productData.isShoppable) {
      var $addToBag = $qs.find('.js-add-to-cart');

      if ($addToBag) {
        $addToBag.removeClass('button--disabled').attr('disabled', false);
      }
    }
    // Make some items disappear before the big reveal.
    $('body').removeClass('qs-display-first');
    $activeContainers.hide();
    $('.js-quickshop', $container).remove();
    $container.append($qs[0]).slideDown();

    // Set up an event trigger for the inline close button.
    $(document).on('click', '.js-quickshop-close-inline', function (e) {
      e.preventDefault();
      $product.trigger('product.quickshop.close.inline');
    });
    // Set up an event trigger for closing qs when the container is clicked.
    $(document).on('click', '.js-qs-inline-overlay', function () {
      $product.trigger('product.quickshop.close.inline');
    });

    $grid.trigger('product.quickshop.animation.before');

    // Animate the window to bring the quickshop container into view.
    var shopTop = $container.offset().top;

    quickshopTop = shopTop - prodHeight - navHeight;

    $('html, body').animate(
      {
        scrollTop: quickshopTop
      },
      {
        duration: animationSpeed
      }
    );

    $allProducts.removeClass('active');
    $product.addClass('active');
    // this .css({'overflow': ''}) fixes a chrome bug. @todo find out why we need it
    $container.addClass('inline-active').css({ overflow: '' });
    $('.product-quickshop', $container).css({
      'margin-top': -prodHeight,
      height: prodHeight
    });
    $inlineOverlay.removeClass('hidden');
  });

  /**
   * QS Launcher
   * PRODUCT GRID AS CAROUSEL - INLINE Quick Shop
   * product.quickshop.launch.inlineCarousel
   * single qs container loads below carousel, moves up and obfuscates the grid
   */
  $(document).on('product.quickshop.launch.inlineCarousel', '.js-product', function () {
    // Store relevant product values.
    var $product = $(this);
    var productID = $product.data('product-id');
    var productData = prodcat.data.getProduct(productID);
    // Store other elements for inserting product info into quickshop container.
    var $gridWrap = $product.closest('.product-grid-wrapper');
    var $grid = $product.closest('.js-product-grid');
    var $allProducts = $('.js-product-brief', $grid);
    var $container = $('.js-quickshop-container--carousel', $gridWrap);
    var $carouselDim = $('.js-qs-carousel-overlay', $grid);
    // Initialize some other variables
    var alreadyActive = $product.hasClass('active');
    var template = 'product_quickshop';
    var animationSpeed = 400;
    var quickshopTop = 0;
    var prodHeight = $container.prev().height();
    // header and site banner heights to help account for offsets
    var navHeight = $('.site-header').height();
    var $siteBanner = $('body').find('.js-nav--banner');
    var siteBannerHeight = $siteBanner.height();

    // detect header and site banner heights
    if ($('html').hasClass('site-banner__is-open')) {
      navHeight = navHeight + siteBannerHeight;
    }

    // Close the quickshop inline if it is already active (and thefore already open).
    if (alreadyActive) {
      $product.trigger('product.quickshop.close.inlineCarousel');

      return;
    }

    // Render the quickshop template.
    var rendered = site.template.get({
      name: template,
      data: productData
    });

    // Make some items disappear before the big reveal.
    $('.js-quickshop', $container).remove();
    $container.append(rendered).slideDown();

    // Set up an event trigger for the inline close button.
    $container.on('click', '.js-quickshop-close-inline', function (e) {
      e.preventDefault();
      $container.removeClass('left-active');
      $product.trigger('product.quickshop.close.inlineCarousel');
    });
    // Set up an event trigger for clicking the faded grid
    $(document).on('click', '.js-qs-carousel-overlay', function () {
      $container.removeClass('left-active');
      $product.trigger('product.quickshop.close.inlineCarousel');
    });

    $grid.trigger('product.quickshop.animation.before');

    // Animate the window to bring the quickshop container into view.
    var carouselTop = $gridWrap.offset().top;

    quickshopTop = carouselTop - navHeight;

    $('html, body').animate(
      {
        scrollTop: quickshopTop
      },
      {
        duration: animationSpeed
      }
    );

    $allProducts.removeClass('active');
    $product.addClass('active');
    if ($product.parent().hasClass('slick-current')) {
      $container.addClass('left-active');
    }
    // this .css({'overflow': ''}) fixes a chrome bug. @todo find out why we need it
    $container.addClass('inline-active').css({ overflow: '' });
    $('.product-quickshop', $container).css({
      'margin-top': -prodHeight,
      height: prodHeight
    });
    $carouselDim.removeClass('hidden');
  });

  /**
   * QS Launcher
   * COLORBOX OVERLAY VERSION OF QS
   * product.quickshop.launch.overlay
   * Uses the supplied .js-product to load the proper product data.
   * Then fires up an overlay with the product_quickshop.mustache template.
   */
  $(document).on('product.quickshop.launch.overlay', '.js-product', function () {
    // Store relevant product values
    var productID = $(this).data('product-id');
    var productData = prodcat.data.getProduct(productID);
    var template = 'product_quickshop';
    // Render the quickshop template
    var rendered = site.template.get({
      name: template,
      data: productData
    });

    generic.overlay.launch({
      content: rendered,
      cssClass: 'qs-overlay-cbox',
      scrolling: false,
      onComplete: function () {
        $('document').trigger('product.quickshop.launch.overlay.complete');
        $.colorbox.resize();
      }
    });
  });

  /**
   * QS Closer
   * STANDARD MPP
   * product.quickshop.close.inline
   * behavior specific to normal product grid
   * Closes the inline quickshop template according to the .js-product supplied.
   */
  $(document).on('product.quickshop.close.inline', '.js-product', function () {
    var $product = $(this);
    var productID = $product.data('product-id');
    var $grid = $product.closest('.js-product-grid');
    var $inlineOverlay = $('.js-qs-inline-overlay', '.js-mpp-container');
    var $container = $('.js-quickshop-container--' + productID, $grid);
    var animationSpeed = 400;

    $('body').removeClass('qs-display-first');
    $container.removeClass('inline-active');
    $product.removeClass('active');
    $inlineOverlay.addClass('hidden');

    $container.slideUp(animationSpeed, function () {
      $grid.trigger('product.quickshop.animation.after');
    });
  });

  /**
   * QS Closer
   * CAROUSEL VERSION
   * product.quickshop.close.inlineCarousel
   * behavior specific to single qs inside carousel
   * Closes the inline quickshop template according to the .js-product supplied.
   */
  $(document).on('product.quickshop.close.inlineCarousel', '.js-product', function () {
    var $product = $(this);
    var $grid = $product.closest('.js-product-grid');
    var $container = $('.js-quickshop-container--carousel');
    var $carouselDim = $('.js-qs-carousel-overlay', '.js-product-grid');
    var animationSpeed = 400;

    $container.removeClass('inline-active');
    $product.removeClass('active');
    $carouselDim.addClass('hidden');

    $container.slideUp(animationSpeed, function () {
      $grid.trigger('product.quickshop.animation.after');
    });
  });

  /**
   * Trigger / click events for each style listed below
   * In response to clicking the inline launch link, find the closest product
   * and then trigger the appropriate event for the code above.
   */

  // STANDARD MPP, INLINE - LAUNCH
  $('.product-grid--normal').on('click', '.js-quickshop-launch-inline', function (e) {
    e.preventDefault();
    $(this).closest('.js-product').trigger('product.quickshop.launch.inline');
    // if this was clicked from the first link make sure the qs loads left
    // other columns load right
    if ($(this).closest('.product-grid__item').hasClass('grid__item--first-col')) {
      $('body').addClass('qs-display-first');
    }
  });

  // / CAROUSEL, INLINE - LAUNCH
  $('.product-grid--carousel').on('click', '.js-quickshop-launch-inline', function (e) {
    e.preventDefault();
    // left-active
    // .slick-current
    $(this).closest('.js-product').trigger('product.quickshop.launch.inlineCarousel');
    // if this was clicked from the first link make sure the qs loads left
  });

  // OVERLAY STYLE - LAUNCH
  $(document).on('click', '.js-quickshop-launch-overlay', function (e) {
    e.preventDefault();
    $(this).closest('.js-product').trigger('product.quickshop.launch.overlay');

    if (Drupal.settings.common.gdpr_cookie_set) {
      if ($.cookie('ELCPrivacyStatus') && $.cookie('ELCPrivacyStatus') === 'REQUIRED') {
        $('.js-share-container').hide();
      }
    }
  });

  // OVERLAY STYLE - LAUNCH - Simpler version
  // Example: <a class="js-quickshop-trigger js-product" product-id="PROD1234" href="#">Link</a>
  $(document).on('click', '.js-quickshop-trigger', function (e) {
    e.preventDefault();
    $(this).trigger('product.quickshop.launch.overlay');
  });
})(jQuery);
